import React from 'react';
import {FeatureApp, createFeatureAppIntegrator} from '@smart/react-utils';
import {getClientConfig} from '@app/utils/src/feature-app';
// New
import '@smart/react-components/index.css';
// Old
import './index.module.scss';

const clientConfig = getClientConfig();

createFeatureAppIntegrator({
  App: ({endpointDirectory: {envName}}) => (
    <React.Fragment>
      <FeatureApp name={process.env.APP!} envName={envName} />
      <FeatureApp name="payment-calculator" envName={envName} />
    </React.Fragment>
  ),
  id: `${process.env.APP}-feature-app-integrator`,
  clientConfig,
});
